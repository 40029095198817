<template>
  <div>
    <!-- 导航栏 -->
    <div id="app">
      <Ztl />
    </div>
    <!-- 新增图片展示区 -->
    <div class="image-gallery">
      <img src="../assets/fwyzc/fuwuzctp.png" alt="Image 1 Description">
    </div>

    <!-- 竭诚服务 -->
    <div class="zzzs-section">
      <h2>
        <strong class="jiechenfuwu">
          <span class="title-text">竭诚</span>
          <span class="highlighted-text">服务</span>
        </strong>
      </h2>
      <h2>
        <span class="highlighted-text1">---------- SERVE WHOLEHEARTEDL ----------</span>
      </h2>
    </div>
    <!-- 竭诚服务 -->
    <div class="fuwu" >   <!--:style="{ backgroundImage: `url(${backgroundImage})` }"-->
      <ul class="fuwu-list">
        <li class="fuwu-item" v-for="(advantage, index) in formattedAdvantages1" :key="index">
          <div class="item-content">
            <img :src="advantage.image" :alt="advantage.title">
            <p>{{ advantage.title }}</p>
            <span v-html="advantage.description"></span>
          </div>
        </li>
      </ul>
    </div>




    <!-- 合作流程 -->
    <div class="zzzs-section">
      <h2>
        <strong class="hezuoliuchen">
          <span class="title-text">合作</span>
          <span class="highlighted-text">流程</span>
        </strong>
      </h2>
      <h2>
        <span class="highlighted-text1">---------- COOPERATION PROCESS ----------</span>
      </h2>
    </div>
    <!-- 合作流程 -->
    <div class="youshi" >   <!--:style="{ backgroundImage: `url(${backgroundImage})` }"-->
      <ul class="youshi-list">
        <li class="youshi-item" v-for="(advantage, index) in formattedAdvantages" :key="index">
          <div class="item-content">
            <img :src="advantage.image" :alt="advantage.title">
            <p>{{ advantage.title }}</p>
            <span v-html="advantage.description"></span> <!-- 使用v-html渲染包含<br>的描述 -->
          </div>
        </li>
      </ul>
    </div>


    <!-- 施工现场 -->
    <div class="zzzs-section">
      <h2>
        <strong class="shigongxianchang">
          <span class="title-text">施工</span>
          <span class="highlighted-text">现场</span>
        </strong>
      </h2>
      <h2>
        <span class="highlighted-text1">---------- COOPERATION PROCESS ----------</span>
      </h2>
    </div>
    <!-- 图片轮播 -->
    <div class="carousel-container">
      <el-carousel indicator-position="outside" height="250px">
        <el-carousel-item v-for="(imageGroup, groupIndex) in groupedImages" :key="groupIndex">
          <div class="carousel-row">
            <div class="carousel-image-box" v-for="(item, index) in imageGroup" :key="index">
              <img :src="item.src" alt="Image" class="carousel-image">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 底部栏 -->
    <div id="app">
      <Footer />
    </div>
  </div>

</template>

<script>
import Ztl from "@/components/Ztl.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Lianxiwomen",
  components: {
    Footer,
    Ztl,

  },
  computed: {
    formattedAdvantages() {
      return this.advantages.map(advantage => ({
        ...advantage,
        description: advantage.description.replace(/\n/g, '<br>')
      }));
    },
    formattedAdvantages1() {
      return this.advantages1.map(advantage => ({
        ...advantage,
        description: advantage.description.replace(/\n/g, '<br>')
      }));
    },
    groupedImages() {
      const chunkSize = 4;
      let result = [];
      for (let i = 0; i < this.images.length; i += chunkSize) {
        result.push(this.images.slice(i, i + chunkSize));
      }
      return result;
    },
  },
  data() {
    return {
      advantages: [
        {
          title: '整理需求',
          description: '结合企业及所属行业情况，\n' +
              '提出委托需求',
          image: require('@/assets/lxwm/lc1.png'),
        },
        {
          title: '方案初定',
          description: '根据需求评估定位效果，\n' +
              '初步提出整体方案',
          image: require('@/assets/lxwm/lc2.png'),
        },
        // 添加更多板块...
        {
          title: '现场考察',
          description: '前往实地进行考察，\n' +
              '商谈项目合作细节',
          image: require('@/assets/lxwm/lc3.png'),
        },
        {
          title: '商务对接',
          description: '敲定具体合作细节，\n' +
              '签订合同',
          image: require('@/assets/lxwm/lc4.png'),
        },
        {
          title: '项目实施',
          description: '技术人员全程指导，\n' +
              '协助完成整套系统搭建',
          image: require('@/assets/lxwm/lc5.png'),
        },
        {
          title: '售后服务',
          description: '提供后续软硬件支持，\n' +
              '24小时随时为你服务',
          image: require('@/assets/lxwm/lc6.png'),
        },
      ],

      advantages1: [
        {
          title: '专业技术团队',
          description: '1000+落地项目\n' +
              '创新技术团队',
          image: require('@/assets/fwyzc/fw1.png'),
        },
        {
          title: '1对1技术支持',
          description: '工程师一对一服务\n' +
              '免费培训服务',
          image: require('@/assets/fwyzc/fw2.png'),
        },
        // 添加更多板块...
        {
          title: '现场全程跟进',
          description: '现场指导安装+调试\n' +
              '现场问题处理服务',
          image: require('@/assets/fwyzc/fw3.png'),
        },
        {
          title: '7*24小时响应',
          description: '24小时随时待命\n' +
              '远程问题处理和指导',
          image: require('@/assets/fwyzc/fw4.png'),
        },
      ],
      images: [
        { src: require('@/assets/fwyzc/xc1.jpg') },
        { src: require('@/assets/fwyzc/xc2.jpg') },
        { src: require('@/assets/fwyzc/xc3.jpg') },
        { src: require('@/assets/fwyzc/xc4.jpg') },
        { src: require('@/assets/fwyzc/xc5.jpg') },
        { src: require('@/assets/fwyzc/xc5.jpg') },
      ],
    }
  },
  created() {

  },
  methods: {}
}
</script>

<style scoped>
.zzzs-section {
  text-align: center;
}

.title-text {
  color: #f64a4a;
  font-size: 35px;
}

.highlighted-text1 {
  color: #4a83fd;
  font-size: 20px;
}

.highlighted-text {
  color: #4a83fd;
  font-size: 35px;
}




.youshi {
  display: flex;
  justify-content: center;
  align-items: stretch;
  position: relative;
  height: fit-content; /* 自动适应内容高度 */
  background-size: cover;
  background-position: center;
}

.youshi-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.youshi-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative; /* 添加相对定位，确保内容在背景之上 */
  z-index: 1; /* 设置较高的z-index，确保内容在背景之上 */
  width: calc(33.33% - 100px); /* 计算每列的宽度，留出间距 */
  margin-bottom: 20px;
  padding: 20px;
  transition: all 0.3s ease; /* 添加过渡效果，使所有变化平滑 */
}

.youshi-item img {
  max-width: 100%;
  height: auto;
}

.youshi-item p {
  font-size: 25px; /* 增加字体大小 */
  margin: 10px 0;
  color: #041728; /* 设置title文字颜色 */
}

.youshi-item span {
  color: #041728; /* 设置title和description文字颜色 */
}

.youshi-item:hover p {
  color: #f62222; /* 鼠标悬停时的文字颜色 */
}

/* 为每个功能模块添加现代风格的边框和白色背景 */
.youshi-item {
  border: 1px solid #616261; /* 深灰色边框 */
  border-radius: 5px; /* 圆角 */
  padding: 30px; /* 内边距，使内容与边框之间有空隙 */
  background-color: #f7f9fd; /* 设置背景颜色为白色 */
  transition: all 0.3s ease; /* 平滑过渡效果 */
}





.fuwu {
  display: flex;
  justify-content: center;
  align-items: stretch;
  position: relative;
  height: fit-content; /* 自动适应内容高度 */
  background-size: cover;
  background-position: center;
  margin: 0 auto; /* 新增，水平居中 */
  max-width: 1200px; /* 或者设置一个具体的最大宽度，根据你的布局需求 */
}

.fuwu-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 100px;
}

.fuwu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative; /* 添加相对定位，确保内容在背景之上 */
  z-index: 1; /* 设置较高的z-index，确保内容在背景之上 */
  width: calc(33.33% - 200px); /* 计算每列的宽度，留出间距 */
  margin-bottom: 60px;
  padding: 20px;
  transition: all 0.3s ease; /* 添加过渡效果，使所有变化平滑 */
}
.fuwu-item img {
  max-width: 120%;
  height: auto;
}
.fuwu-item p {
  font-size: 20px; /* 增加字体大小 */
  margin: 10px 0;
  color: #041728; /* 设置title文字颜色 */
}

.fuwu-item span {
  color: #041728; /* 设置title和description文字颜色 */
}

.fuwu-item:hover p {
  color: #f62222; /* 鼠标悬停时的文字颜色 */
}
.carousel-container {
  width: 100%;
  margin: auto;
}

.carousel-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1px;
}

.carousel-image-box {
  flex: 0 0 calc(16% - 1px);
  text-align: center;
}

.carousel-image {
  max-width: 100%;
  height: auto;
}
</style>